<template>
  <div class="container dao-page">
    <div>
      <h2 class="stake-page-title">SmartPad DAO</h2>
    </div>

    <template v-if="!isShowDaoPage">
      <template v-if="!web3Instance.active">
        <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connect">Connect wallet</div>
      </template>

      <template v-else>
        <div class="text-multicolor animation-blink">
          Please, connect to {{ $root.allowedChains[homeChain].name }} network first.
        </div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connectToHomeChain">
          Connect to {{ $root.allowedChains[homeChain].name }} network
        </div>
      </template>
    </template>

    <template v-else>
      <dao-banner
        v-if="isShowBannerDao"
        :multiplier="availableMultiplier"
        @on-close="hideBannerDao"
        @apply-multiplier="applyMultiplier"
      />

      <div class="row m-h--16">
        <div class="col-6 col-md-12 p-h-16 m-b-24">
          <lock-a-pad :allowed-timestamp="allowedTimestamp" @update-goverance="loadGovernanceData" @days="setDays" />
        </div>

        <div class="col-6 col-md-12 p-h-16 m-b-24">
          <v-pad-balance :lock-info="lockInfo" :allowed-timestamp="allowedTimestamp" :days="days" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LockAPad from '@DaoPage/LockAPad';
import VPadBalance from '@DaoPage/VPadBalance';
import DaoBanner from '@DaoPage/DaoBanner';
import { useWeb3 } from '../store/web3';
import { ethers } from 'ethers';
import GovernanceAbi from '@/artifacts/Governance.json';

export default {
  name: 'DaoPage',

  components: {
    LockAPad,
    VPadBalance,
    DaoBanner,
  },

  data: () => ({
    homeChain: 4, //process.env.VUE_APP_HOME_CHAIN,
    web3Instance: useWeb3(),

    availableMultiplier: null,
    alreadyAppliedMultiplier: null,

    lockInfo: {},
    governanceContract: null,

    isShowBannerDao: false,

    days: 0,
  }),

  computed: {
    isShowDaoPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.homeChain) {
        return false;
      }

      return true;
    },

    allowedTimestamp() {
      const oneDayInMs = 24 * 60 * 60;
      const nowTimestamp = new Date().getTime() / 1000;
      const unlockTimestamp = +this.lockInfo?.unlockTimestamp?.toString();

      return this.days < Math.ceil((unlockTimestamp - nowTimestamp) / oneDayInMs);
    },
  },

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.loadGovernanceData();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.loadGovernanceData();
      },
    },
  },

  mounted() {
    this.connectToHomeChain();
    this.loadGovernanceData();
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    async connectToHomeChain() {
      if (this.$root.chainId != this.homeChain) {
        await this.$root.setActiveChain(parseInt(this.homeChain));
      }
    },

    async loadGovernanceData() {
      if (!this.$root.chainId || !this.$root.account) {
        await setTimeout(async () => await this.loadGovernanceData(), 1000);
        return;
      }

      if (this.$root.chainId != this.homeChain) {
        return;
      }

      const signer = this.$root.provider?.getSigner();

      this.governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      this.lockInfo = await this.governanceContract.userLock(this.$root.account);

      this.availableMultiplier = parseInt(await this.governanceContract.availableMultiplier(this.$root.account));
      this.alreadyAppliedMultiplier = parseInt(
        await this.governanceContract.alreadyAppliedMultiplier(this.$root.account)
      );

      this.isShowBannerDao = this.availableMultiplier > 0;
    },

    hideBannerDao() {
      this.isShowBannerDao = false;
    },

    async applyMultiplier() {
      this.$root.transactions.listen({
        info: 'Apply multiplier',
        transactionRequest: this.governanceContract.applyMultiplier(),
        onDone: (isSuccess = false) => {
          if (isSuccess) {
            this.alreadyAppliedMultiplier = this.availableMultiplier;
            this.availableMultiplier = null;
          }
        },
      });
    },

    setDays(val) {
      this.days = val;
    },
  },
};
</script>
