<template>
  <ul class="social-menu">
    <li v-for="item in listOptions" :key="item.icon">
      <a :href="item.url" target="_blank" class="social-icon" v-html="getSVGIcon(item.icon)"></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SocialMenu',

  props: {
    listOptions: {
      type: [Array, null],
      required: true,
    },
  },

  methods: {
    getSVGIcon(name) {
      let iconSvg = ''; // [ twitter, medium, telegram, mail ]

      switch (name) {
        case 'twitter':
          iconSvg = `
            <svg viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.85313 20.4386C17.2875 20.4386 22.4484 12.6214 22.4484 5.85421C22.4484 5.63546 22.4484 5.41358 22.4391 5.19483C23.4438 4.46747 24.311 3.56694 25 2.53546C24.0614 2.94929 23.0668 3.22203 22.0484 3.34483C23.1211 2.70364 23.9246 1.69478 24.3094 0.50577C23.3015 1.10284 22.1985 1.52198 21.0484 1.74483C20.2753 0.921432 19.2522 0.375954 18.1376 0.192892C17.0231 0.00982875 15.8793 0.199398 14.8833 0.732235C13.8874 1.26507 13.095 2.11144 12.6288 3.14024C12.1626 4.16904 12.0487 5.32285 12.3047 6.42296C10.2652 6.32069 8.27003 5.79088 6.4485 4.86787C4.62698 3.94486 3.01982 2.64928 1.73125 1.06515C1.07709 2.19496 0.877459 3.5314 1.17288 4.80306C1.46831 6.07473 2.23665 7.18629 3.32187 7.91202C2.50866 7.88439 1.71331 7.66602 1 7.27452V7.34483C1.0014 8.5284 1.41142 9.67518 2.16074 10.5913C2.91006 11.5075 3.95273 12.1368 5.1125 12.373C4.67229 12.4942 4.21755 12.5547 3.76094 12.5526C3.43904 12.5536 3.11778 12.5238 2.80156 12.4636C3.12935 13.4824 3.76761 14.3732 4.62695 15.0112C5.48628 15.6492 6.52365 16.0024 7.59375 16.0214C5.77587 17.4492 3.53031 18.2236 1.21875 18.2198C0.811439 18.2215 0.404411 18.1981 0 18.1495C2.34612 19.6452 5.07078 20.4394 7.85313 20.4386Z"
              />
            </svg>`;
          break;
        case 'medium':
          iconSvg = `
            <svg viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.96483 4.07837C2.98003 3.93109 2.95929 3.7824 2.90432 3.64439C2.84934 3.50638 2.76167 3.38295 2.64844 3.28414L0.315071 0.547307V0.136719H7.56301L13.1657 12.0951L18.0909 0.136719H25V0.546024L23.0028 2.40779C22.9192 2.47115 22.8546 2.55515 22.8156 2.65104C22.7767 2.74694 22.7648 2.85124 22.7813 2.9531V16.6385C22.7646 16.7404 22.7763 16.8448 22.8152 16.9407C22.8542 17.0367 22.919 17.1206 23.0028 17.1838L24.9525 19.0456V19.4549H15.1498V19.0456L17.1654 17.1376C17.3658 16.9452 17.3658 16.8874 17.3658 16.5936V5.53082L11.7512 19.41H10.9932L4.45713 5.53082V14.8319C4.40308 15.2233 4.53623 15.6172 4.81966 15.9007L7.44569 19.002V19.4113H0V19.002L2.62603 15.9007C2.76418 15.7606 2.86676 15.591 2.92559 15.4054C2.98441 15.2198 2.99785 15.0235 2.96483 14.8319V4.07837Z"
              />
            </svg>`;
          break;
        case 'telegram':
          iconSvg = `
            <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.6405 8.58979C8.04634 5.86355 12.3179 4.06624 14.4552 3.19787C20.5576 0.718504 21.8256 0.287811 22.6521 0.273589C22.8338 0.270461 23.2403 0.314467 23.5036 0.523145C23.7259 0.699348 23.787 0.937374 23.8163 1.10443C23.8456 1.27149 23.882 1.65206 23.853 1.94943C23.5223 5.3435 22.0915 13.58 21.3635 17.3814C21.0555 18.9899 20.449 19.5293 19.8618 19.5821C18.5857 19.6968 17.6167 18.7583 16.3808 17.9669C14.4468 16.7285 13.3542 15.9576 11.477 14.7492C9.30745 13.3527 10.7139 12.5851 11.9503 11.3307C12.2738 11.0024 17.8962 6.00693 18.005 5.55376C18.0186 5.49708 18.0313 5.28582 17.9028 5.17427C17.7743 5.06271 17.5847 5.10086 17.4478 5.1312C17.2538 5.1742 14.1643 7.16897 8.1791 11.1155C7.30214 11.7037 6.50781 11.9903 5.79612 11.9753C5.01154 11.9588 3.50232 11.542 2.38037 11.1857C1.00425 10.7488 -0.0894564 10.5178 0.00578119 9.77568C0.0553868 9.38916 0.600293 8.99386 1.6405 8.58979Z"
              />
            </svg>`;
          break;
        case 'mail':
          iconSvg = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 20" fill="none">
              <path d="M26 0H0V20H26V0ZM23.4 5L13 11.25L2.6 5V2.5L13 8.75L23.4 2.5V5Z" />
            </svg>`;
          break;
        default:
          break;
      }

      return iconSvg;
    },
  },
};
</script>
