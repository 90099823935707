<template>
  <div class="voting-ido box-shadow">
    <div class="voting-box">
      <div class="proposals-list-votes">
        <div
          class="positive"
          :class="{ 'disabled': !haveVotes || alreadyVoted }"
          @click="haveVotes && !alreadyVoted ? accept() : null"
        >
          <img src="/static/images/proposals/thumbs-up.svg" />
          Accept
        </div>
        <div class="flex-sh__0 p-h-8" :style="`color: ${getCountColor};`">
          {{ $formatAmount(resultVoting, 'n/a', true) }}
        </div>
        <div
          class="negative"
          :class="{ 'disabled': !haveVotes || alreadyVoted }"
          @click="haveVotes && !alreadyVoted ? reject() : null"
        >
          <img src="/static/images/proposals/thumbs-down.svg" />
          Reject
        </div>
      </div>

      <div class="flex-h__between">
        <div class="accept">+ {{ $formatAmount(positiveVotes, 'n/a', true) }}</div>
        <div class="reject">- {{ $formatAmount(negativeVotes, 'n/a', true) }}</div>
      </div>
    </div>

    <div class="voting-info">
      <div class="row">
        <div class="col-7">
          <div class="voting-info-title">Minimum votes</div>
          <div class="voting-info-value">1,000</div>
        </div>
        <div class="col-5">
          <div class="voting-info-title">Total votes</div>
          <div class="voting-info-value">{{ $formatAmount(totalVotes, 'n/a', true) }}</div>
        </div>
      </div>
    </div>

    <div v-if="alreadyVoted" class="voting-already">Already voted</div>
    <div v-else-if="!haveVotes" class="voting-already voting-get-vpad">
      <router-link :to="{ name: 'DaoPage' }">Click here to get vPAD</router-link>
    </div>
    <div class="voting-already" v-else>
      <div>vPAD balance</div>
      <div class="voting-balance">{{ $formatUnitsToken(lockInfo?.votes || null, 18, 2) }} vPAD</div>
    </div>

    <div v-if="launchInLabel" class="voting-time">
      <img class="flex-sh__0 m-r-16" src="/static/images/ido/clock.svg" alt="clock" />
      <span v-html="launchInLabel"></span>
    </div>
    <div v-else class="voting-time">
      <span>Voting period already ends</span>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import GovernanceAbi from '@/artifacts/Governance.json';
import BigNumber from 'bignumber.js/bignumber.mjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {
  name: 'ProposalVoting',

  props: ['poolInfo'],

  data: () => ({
    launchInLabel: null,

    positiveVotes: 0,
    negativeVotes: 0,
    totalVotes: 0,

    governanceContract: null,
    isUpdateInProgress: false,

    lockInfo: {},
    alreadyVoted: false,
  }),

  computed: {
    getCountColor() {
      if (this.resultVoting < 0) {
        return '#C93030';
      } else if (this.resultVoting > 0) {
        return '#2BBF0A';
      }
      return '#ffffff';
    },

    resultVoting() {
      return this.positiveVotes - this.negativeVotes;
    },

    haveVotes() {
      return this.lockInfo?.votes > 0;
    },
  },

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.updateInfo();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.updateInfo();
      },
    },
  },

  mounted() {
    this.countdown();
  },

  beforeUnmount() {
    clearInterval(this.refreshCountdown);
  },

  methods: {
    countdown() {
      this.launchInLabel = this.getLaunchInLabel();

      this.refreshCountdown = setInterval(() => {
        this.launchInLabel = this.getLaunchInLabel();
      }, 1000);
    },

    getLaunchInLabel() {
      const convertedStartDate = dayjs.unix(this.poolInfo?.votingDateUTC);

      if (!this.poolInfo?.votingDateUTC) {
        return null;
      }

      const oneDayInMs = 24 * 60 * 60 * 1000;
      const diff = convertedStartDate.diff(dayjs(), 'ms');

      if (diff < 0) {
        return null;
      }

      if (diff > oneDayInMs) {
        const countOfDays = parseInt(diff / oneDayInMs);
        return `Voting ends in: <span class="time">${countOfDays} day${countOfDays > 1 ? 's' : ''}</span>`;
      }

      return `Voting ends in: <span class="time">${new Date(diff).toISOString().substr(11, 8)}</span>`;
    },

    accept() {
      if (!this.launchInLabel) {
        return;
      }

      this._vote(true);
    },

    reject() {
      if (!this.launchInLabel) {
        return;
      }

      this._vote(false);
    },

    _vote(isAccept = true) {
      const signer = this.$root.provider?.getSigner();

      const governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      this.$root.transactions.listen({
        info: `Vote for proposal`,
        transactionRequest: governanceContract.voteProposal(
          this.poolInfo.createdAtTimestamp, //createdAt == proposalIndex
          isAccept //bool isAccept
        ),
        onDone: async (isSuccess = false) => {
          console.log('Voting result is', isSuccess);

          if (isSuccess) {
            await this.updateInfo();
          }
        },
      });
    },

    async updateInfo() {
      this.totalVotes = null;
      this.amountToUnstake = null;

      if (this.isUpdateInProgress) {
        return;
      }

      this.isUpdateInProgress = true;

      if (!this.$root.chainId) {
        this.isUpdateInProgress = false;
        await setTimeout(async () => await this.updateInfo(), 1000);
        return;
      }

      if (!this.poolInfo?.createdAtTimestamp) {
        this.isUpdateInProgress = false;
        await setTimeout(async () => await this.updateInfo(), 1000);
        return;
      }

      const signer = this.$root.provider?.getSigner();

      this.governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      const info = await this.governanceContract.proposals(this.poolInfo.createdAtTimestamp);

      if (info) {
        const positiveVotes = parseInt(
          BigNumber(info.positiveVotes.toString())
            .dividedBy(10 ** 18)
            .toString(),
          10
        );

        this.positiveVotes = positiveVotes;

        const negativeVotes = parseInt(
          BigNumber(info.negativeVotes.toString())
            .dividedBy(10 ** 18)
            .toString(),
          10
        );

        this.negativeVotes = negativeVotes;

        this.totalVotes = positiveVotes + negativeVotes;
      }

      const userInfo = await this.governanceContract.userProposalVoteInfo(
        this.$root.account,
        this.poolInfo.createdAtTimestamp
      );

      this.lockInfo = await this.governanceContract.userLock(this.$root.account);

      this.alreadyVoted = userInfo.negativeVotes > 0 || userInfo.positiveVotes > 0;

      this.isUpdateInProgress = false;
    },
  },
};
</script>
