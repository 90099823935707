<template>
  <footer class="m-t-a">
    <div class="container">
      <div class="footer-wrap">
        <div class="logo">
          <a href="/">
            <img src="/static/images/logo-long.svg" alt="logo" height="35" />
          </a>
        </div>

        <social-menu :list-options="socialList" />

        <a href="https://smartpad.network/" target="_blank" class="btn-big btn-transp">Go to site</a>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialMenu from '@/components/SocialMenu';

export default {
  name: 'TheFooter',

  components: {
    SocialMenu,
  },

  data: () => ({
    socialList: [
      {
        icon: 'twitter',
        url: 'https://twitter.com/SmartPad_launch',
      },
      {
        icon: 'medium',
        url: 'https://smartpadofficial.medium.com/',
      },
      {
        icon: 'telegram',
        url: 'https://t.me/smartpad_group',
      },
      {
        icon: 'mail',
        url: 'mailto:info@smartpad.network',
      },
    ],
  }),

  methods: {},
};
</script>
