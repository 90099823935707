<template>
  <g-modal
    v-model="isShowModal"
    name="fee"
    :classes="['vfm--modal', 'fee-modal']"
    :click-to-close="true"
    :is-show-close-button="true"
    :width="580"
    @close-modal="closeModal"
  >
    <template #header>
      <h3 class="m-b-36">Fee</h3>
      <div class="flex-wrap__wrap flex-v__center">
        <div class="description m-r-8">The fee for creating the proposal will be paid in</div>

        <label class="select-checkbox">
          <input type="checkbox" :checked="payInPAD" @change="onChange" />
          <div class="toggler-slider">
            <div class="toggler-knob" data-left="ETH" data-right="PAD"></div>
          </div>
        </label>
      </div>
    </template>

    <template #content>
      <div class="flex-v__center flex-h__between flex-wrap__wrap m-b-20">
        <div class="price">
          {{ $formatUnitsToken(feeAmount || null) }} {{ payInPAD ? 'PAD' : 'ETH' }}
          <span v-if="false" class="small">(1,3 USDT)</span>
        </div>

        <div v-if="false" class="balance">Balance: FIXME Pad</div>
      </div>

      <div class="row">
        <div class="col-6">
          <div
            v-if="isApproveTransactionInProgress"
            class="btn btn-big btn-approve width-full btn-font-normal no-click animation-blink"
          >
            Approving...
          </div>

          <button
            v-else-if="payInPAD && !isAllowanceGiven"
            class="btn btn-big btn-approve width-full btn-font-normal"
            :class="{
              'disabled': feeAmount <= 0 || isApproveTransactionInProgress,
            }"
            @click="approve()"
          >
            Approve
          </button>
        </div>

        <div class="col-6">
          <div
            v-if="isCreateTransactionInProgress"
            class="btn btn-big btn-multicolor width-full btn-font-normal no-click animation-blink"
          >
            Creating...
          </div>

          <div
            v-else
            class="btn btn-big btn-multicolor width-full btn-font-normal"
            :class="{ 'disabled': feeAmount <= 0 || (payInPAD && !isAllowanceGiven) }"
            @click="create()"
          >
            Create
          </div>
        </div>
      </div>
    </template>
  </g-modal>
</template>

<script>
import BigNumber from 'bignumber.js/bignumber.mjs';
import PADAbi from '@/artifacts/PAD.json';
import { ethers } from 'ethers';
import GovernanceAbi from '@/artifacts/Governance.json';

export default {
  name: 'FeeModal',

  props: {
    proposalInfo: {
      type: Object,
      required: true,
    },
    feeInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isShowModal: true,

    allowancePADGiven: null,
    isApproveTransactionInProgress: false,
    isCreateTransactionInProgress: false,

    payInPAD: true,
  }),

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
  },

  computed: {
    isAllowanceGiven() {
      if (!this.allowancePADGiven) {
        return false;
      }

      if (this.feeAmount === 'null' || this.feeAmount === null || !+this.feeAmount) {
        return false;
      }

      const feeAmount = BigNumber(this.feeAmount.toString());

      return this.allowancePADGiven.isGreaterThanOrEqualTo(feeAmount);
    },

    feeAmount() {
      if (!this.feeInfo?.padFee) {
        return 0;
      }

      return this.payInPAD ? this.feeInfo?.padFee : this.feeInfo?.ethFee;
    },
  },

  methods: {
    async updateAccountInfo() {
      const signer = this.$root.provider?.getSigner();

      this.PADContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.padTokenContract,
        PADAbi,
        signer
      );

      const allowancePAD = await this.PADContract.allowance(
        this.$root.account,
        this.$root.allowedChains[this.$root.chainId].governanceContractAddress
      );

      this.allowancePADGiven = BigNumber(allowancePAD.toString());
    },

    showModal() {
      this.$vfm.show('fee');
    },

    closeModal() {
      this.$vfm.hide('fee');
    },

    async create() {
      if (this.payInPAD && !this.isAllowanceGiven) {
        return;
      }

      this.isCreateTransactionInProgress = true;

      const signer = this.$root.provider?.getSigner();

      const governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      console.log('Started saving the proposal at ', this.proposalInfo.createdAtTimestamp);

      if (this.payInPAD) {
        this.$root.transactions.listen({
          info: `Create proposal (fee ${this.$formatUnitsToken(this.feeAmount || null)} PAD)`,
          transactionRequest: governanceContract.createProposalWithPAD(
            this.proposalInfo.createdAtTimestamp, //createdAt
            this.proposalInfo.votingDateUTC //endsAt
          ),
          onDone: async (isSuccess = false) => {
            console.log('Save proposal to contract result is ', isSuccess);

            if (isSuccess) {
              const response = await this.$json.post({
                url: process.env.VUE_APP_API_URL_PREFIX + '/proposals',
                data: {
                  createdAt: this.proposalInfo.createdAtTimestamp,
                  proposalInfo: JSON.stringify(this.proposalInfo),
                },
              });

              console.log('Saved to Firebase', response);

              this.isCreateTransactionInProgress = false;
              await this.$router.push({ name: 'ProposalsPage' });
            }

            this.isCreateTransactionInProgress = false;
          },
        });
      } else {
        this.$root.transactions.listen({
          info: `Create proposal (fee ${this.$formatUnitsToken(this.feeAmount || null)} ETH)`,
          transactionRequest: governanceContract.createProposalWithETH(
            this.proposalInfo.createdAtTimestamp, //createdAt
            this.proposalInfo.votingDateUTC, //endsAt
            {
              from: this.$root.account,
              value: this.feeAmount,
            }
          ),
          onDone: async (isSuccess = false) => {
            console.log('Save proposal to contract result is ', isSuccess);

            if (isSuccess) {
              const response = await this.$json.post({
                url: process.env.VUE_APP_API_URL_PREFIX + '/proposals',
                data: {
                  createdAt: this.proposalInfo.createdAtTimestamp,
                  proposalInfo: JSON.stringify(this.proposalInfo),
                },
              });

              console.log('Saved to Firebase', response);

              this.isCreateTransactionInProgress = false;
              await this.$router.push({ name: 'ProposalsPage' });
            }

            this.isCreateTransactionInProgress = false;
          },
        });
      }
    },

    async approve() {
      this.isApproveTransactionInProgress = true;

      if (!this.payInPAD) {
        return;
      }

      this.$root.transactions.listen({
        info: `Approve ${this.$formatUnitsToken(this.feeAmount || null)} PAD to create proposal`,
        transactionRequest: this.PADContract.approve(
          this.$root.allowedChains[this.$root.chainId].governanceContractAddress,
          '0x' + BigNumber(this.feeAmount.toString()).toString(16)
        ),
        onDone: (isSuccess = false) => {
          this.isApproveTransactionInProgress = false;

          if (isSuccess) {
            this.allowancePADGiven = BigNumber(this.feeAmount.toString());
          }
        },
      });
    },

    onChange({ target: { checked } }) {
      this.payInPAD = Boolean(checked);
    },
  },
};
</script>
