<template>
  <div class="ido-prime">
    <div class="ido-prime_header">
      <div v-if="!isPreview" class="back-page inline-block">
        <router-link :to="{ name: 'ProposalsPage' }" class="m-b-0 m-l-16"></router-link>
      </div>

      <div class="ido-prime_header-logo">
        <img
          :src="poolInfo?.logoUrl"
          class="width-full"
          alt="logo"
          @error="(e) => (e.target.src = '/static/images/logo_ghost.svg')"
        />
      </div>

      <div class="ido-prime_header-info">
        <div class="ido-prime_header-title">
          <div class="inline">{{ truncate(poolInfo?.name, 20, '...') || 'Project name' }}</div>
          <span>/ {{ truncate(poolInfo?.buyTokenSymbol, 6, '...') || 'Token symbol' }}</span>
        </div>
      </div>
    </div>

    <div class="ido-prime_social-button">
      <social-menu :list-options="socialList" class="m-b-8 m-r-20" />

      <div class="btns m-b-8">
        <button v-if="poolInfo?.buyTokenAddress" class="btn btn-small btn-bg-noborder" @click="tokenLinkClicked">
          Token address
        </button>
        <a v-if="poolInfo?.cookyLink" :href="poolInfo?.cookyLink" class="btn btn-small btn-bg-noborder" target="_blank">
          Cooky
        </a>
        <a v-if="poolInfo?.linkGDPR" :href="poolInfo?.linkGDPR" class="btn btn-small btn-bg-noborder" target="_blank">
          GDPR
        </a>
        <a v-if="poolInfo?.linkPP" :href="poolInfo?.linkPP" class="btn btn-small btn-bg-noborder" target="_blank">PP</a>
      </div>
    </div>

    <div class="prime_description">
      <div class="prime_description-title">About the project</div>

      <div ref="description" class="prime_description-text">
        <div class="hidden-text" :class="{ 'min': moreHeight > 150 && isHide }">
          {{ poolInfo?.description || 'Project description...' }}
        </div>
        <div v-if="moreHeight > 150" class="more-text" @click="isHide = !isHide">
          {{ isHide ? 'More' : 'Hide' }}
        </div>
      </div>
    </div>

    <a v-if="poolInfo?.websiteLink" :href="poolInfo?.websiteLink" class="btn-big btn-transp" target="_blank">
      More details
    </a>
  </div>
</template>

<script>
import SocialMenu from '@/components/SocialMenu';

export default {
  name: 'ProposalPrimeInfo',

  components: {
    SocialMenu,
  },

  props: {
    poolInfo: {
      type: Object,
      required: true,
    },

    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    moreHeight: 24,
    isHide: true,
  }),

  computed: {
    socialList() {
      const resultArray = [];

      if (this.poolInfo?.twitterLink) {
        resultArray.push({
          icon: 'twitter',
          url: this.poolInfo?.twitterLink,
        });
      }

      if (this.poolInfo?.telegramLink) {
        resultArray.push({
          icon: 'telegram',
          url: this.poolInfo?.telegramLink,
        });
      }

      if (this.poolInfo?.mediumLink) {
        resultArray.push({
          icon: 'medium',
          url: this.poolInfo?.mediumLink,
        });
      }

      return resultArray;
    },
  },

  created() {
    setTimeout(() => {
      this.moreHeight = this.$refs.description?.clientHeight;
    }, 100);
  },

  methods: {
    tokenLinkClicked() {
      const url = this.$root.allowedChains[this.$root.chainId].explorerUrl + 'token/' + this.poolInfo.buyTokenAddress;
      window.open(url, '_blank');
    },

    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>
