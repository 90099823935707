<template>
  <div class="proposals-list-row box-shadow" :class="{ 'active': isActiveProposal }" @click.stop="voteProposal()">
    <div class="proposals-list-dot"></div>

    <div class="proposals-list-col">
      <img
        :src="item.logoUrl"
        class="proposals-list-img"
        :alt="item.name"
        @error="(e) => (e.target.src = '/static/images/logo_ghost.svg')"
      />
    </div>

    <div class="proposals-list-col proposals-list-name text-overflow">
      {{ item.name || 'Project name' }}
    </div>

    <div class="proposals-list-col proposals-list-abr text-overflow">
      {{ item.buyTokenSymbol || 'Token symbol' }}
    </div>

    <div class="proposals-list-col proposals-list-date">
      <template v-if="item.tgeAtTimestamp">
        <div class="date-main">
          <span>
            <g-date :timestamp="item.tgeAtTimestamp" part="date" />
          </span>
          <g-date :timestamp="item.tgeAtTimestamp" part="time" />
          <div class="inline-block m-l-8 date-UTC">(local)</div>
        </div>

        <div class="date-UTC">
          <g-date :timestamp="item.tgeAtTimestamp" part="datetime" :utc="true" />
          (UTC)
        </div>
      </template>
      <div v-else class="text-multicolor">unknown</div>
    </div>

    <div class="proposals-list-col proposals-list-tge">{{ item.tgePercent }}%</div>

    <div v-if="item.tgePercent != 100" class="proposals-list-col proposals-list-vesting">
      {{ item.vesting.countValue }}
      {{ item.vesting.countValue > 1 ? item.vesting.periodValue + 's' : item.vesting.periodValue }}
    </div>

    <div v-else class="proposals-list-col proposals-list-vesting">n/a</div>

    <div class="proposals-list-col proposals-list-votes">
      <div class="proposals-list-votes-in">
        <div class="positive">
          <img src="/static/images/proposals/thumbs-up.svg" />
          <span>+ {{ $formatAmount(item.info.positiveVotes, 'n/a', true) }}</span>
        </div>
        <div class="flex-sh__0" :style="`color: ${getCountColor};`">{{ $formatAmount(resultVoting, 'n/a', true) }}</div>
        <div class="negative">
          <img src="/static/images/proposals/thumbs-down.svg" />
          <span>- {{ $formatAmount(item.info.negativeVotes, 'n/a', true) }}</span>
        </div>
      </div>
    </div>

    <div class="proposals-list-col">
      <button class="btn-big btn-transp proposals-list-btn" @click.stop="voteProposal()">Vote</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProposalsListRow',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    countLike: 0,
  }),

  computed: {
    isActiveProposal() {
      return this.item.votingDateUTC > parseInt(Date.now() / 1000, 10);
    },

    getCountColor() {
      if (this.resultVoting < 0) {
        return '#C93030';
      } else if (this.resultVoting > 0) {
        return '#2BBF0A';
      }
      return '#ffffff';
    },

    resultVoting() {
      return this.item?.info?.positiveVotes || 0 - this.item?.info?.negativeVotes || 0;
    },
  },

  methods: {
    voteProposal() {
      this.$router.push({
        name: 'ViewProposalsIDO',
        params: { createdAt: this.item.createdAtTimestamp },
      });
    },
  },
};
</script>
